import { Outlet, Navigate } from 'react-router-dom'


const ProtectedRoutesStudent = () => {

    const user = JSON.parse( localStorage.getItem("student"))




    return(
        user ? <Outlet/> : <Navigate to="/"/>);
}

export default ProtectedRoutesStudent