
import React,{useContext, useState,useEffect} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from '../components/Header';
import {socket} from "../App"
import Footer from '../components/Footer';
import Nav from './Nav';
 


const SingleDzongkha = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [email,setEmail] =  useState("");
  const [name,setName] =  useState("");
  const [phone,setPhone] =  useState("");
  const [password,setPassword] =  useState("");
  const [billing,setBilling] =  useState(false);
  const [payment,setPayment] =  useState(false);
  const [loading,setLoading] = useState(false)
  const [freeEvent,setFreeEvent] =  useState(false);
  const [policy,setPolicy] =  useState(false);
  const [seats,setSeats] =  useState(0);
  const [size,setSize] =  useState(0);
  const navigate= useNavigate()
  const [ARresponse,setARresponse]= useState()

  const [data,setData]= useState([])
  const [cart,setCart]= useState([])
  const [details,setDetails]= useState([])
  const [column, setColumn] = useState("")
  const [date, setDate] = useState(new Date())
  const [eventDate, seteventDate] = useState(new Date().setHours(0, 0, 0, 0))
  const [booked,setBooked] = useState([])
  const [taken,setTaken] = useState([])
const {dzongkhaid} = useParams()
// const elements = useElements()
const [pay,setPay] =  useState(false);
const [validation,setValidation] =  useState(false);
const [falseTran,setFalseTran] =  useState(false);
const [startVote,setStartVote] =  useState(false);
const [amount,setAmount] =  useState();
const [vote,setVote] =  useState(0);
const [votefor,setVoteFor] =  useState();
const [bfs_bfsTxnId,setbfs_bfsTxnId] = useState("")
const [bfs_remitterBankId,setbfs_remitterBankId] = useState("")
const [bfs_remitterAccNo,setbfs_remitterAccNo] = useState("")
const [bfs_remitterOtp,setbfs_remitterOtp] = useState("")



let now = new Date();
        let year = now.getFullYear();
        let month = (now.getMonth() + 1).toString().padStart(2, '0');
        let day = now.getDate().toString().padStart(2, '0');
        let hours = now.getHours().toString().padStart(2, '0');
        let minutes = now.getMinutes().toString().padStart(2, '0');
        let seconds = now.getSeconds().toString().padStart(2, '0');

useEffect(()=>{

    fetch(`/singledzongkhasessions/${dzongkhaid}`,{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.post)

      console.log(result.post)
    })
     

},[])

 

const startChat =()=>{

    fetch('/addstudentsdzongkhasessions',{

      method:"put",
      headers:{

        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")



      },
      body:JSON.stringify({
        postId:dzongkhaid,
        date
      })

    }).then(res=> res.json())
      .then(results=>{

        history('/StudentDashboard')
        
      })



  }

  const addVote = ()=>{

    setLoading(true)
    
            fetch('/arrequest',{
    
                method:"post",
                headers:{
                //   Authorization: "Bearer " +localStorage.getItem("jwt"),
          
                  "Content-Type":"application/json"
          
                },
                body:JSON.stringify({
                    bfs_txnAmount:amount,bfs_benfTxnTime:year+month+day+hours+minutes+seconds
                })
          
              }).then(res=> res.json())
                .then(r=>{
                
                //   setData(results.events)
                if(r.data.bfs_responseCode=="00"){         
                       setPayment(true)
                       setStartVote(false)
    
                    setbfs_bfsTxnId(r.data.bfs_bfsTxnId)
                    setARresponse(r.data)
                    setLoading(false)
                }
               
                }).catch(err=>{
                    console.log(err)
                })
          
             
        // fetch('/addVote',{
    
        //     method:"put",
        //     headers:{
        //       Authorization: "Bearer " +localStorage.getItem("jwt"),
      
        //       "Content-Type":"application/json"
      
        //     },
        //     body:JSON.stringify({
        //         votes,participant,show:postid
        //     })
      
        //   }).then(res=> res.json())
        //     .then(results=>{
              
        //       setData(results.events)
        //     })
    
           }   
  const sendAERequest = ()=>{
setLoading(true)

    fetch('/aerequest',{

        method:"post",
        headers:{
        //   Authorization: "Bearer " +localStorage.getItem("jwt"),
  
          "Content-Type":"application/json"
  
        },
        body:JSON.stringify({
            bfs_bfsTxnId,bfs_remitterAccNo,bfs_remitterBankId,
        })
  
      }).then(res=> res.json())
        .then(r=>{
        
        //   setData(results.events)
        setPayment(false)
        setLoading(false)
        setPay(true)
        // if(r.data.bfs_responseCode=="00"){
        //     setbfs_bfsTxnId(r.data.bfs_bfsTxnId)
        //     setARresponse(r.data)
        // }
       
        }).catch(err=>{
            console.log(err)
        })
  
     
// fetch('/addVote',{

//     method:"put",
//     headers:{
//       Authorization: "Bearer " +localStorage.getItem("jwt"),

//       "Content-Type":"application/json"

//     },
//     body:JSON.stringify({
//         votes,participant,show:postid
//     })

//   }).then(res=> res.json())
//     .then(results=>{
      
//       setData(results.events)
//     })


   }
   const sendDRRequest = ()=>{

    setLoading(true)

    fetch('/drrequest',{

        method:"post",
        headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
  
          "Content-Type":"application/json"
  
        },
        body:JSON.stringify({
            bfs_bfsTxnId,bfs_remitterOtp, votes:amount/data.price,week:data.week,postId:dzongkhaid
        })
  
      }).then(res=> res.json())
        .then(r=>{
        
        //   setData(results.events)
        setPay(false)
        if(r.added){
          setLoading(false)
            setValidation(true)
            setDetails(r.added)
            history('/mydzongkhasessions')
        }
        else{

            setFalseTran(true)
        }
        // if(r.data.bfs_responseCode=="00"){
        //     setbfs_bfsTxnId(r.data.bfs_bfsTxnId)
        //     setARresponse(r.data)
        // }
       
        }).catch(err=>{
            console.log(err)
        })
  
     
// fetch('/addVote',{

//     method:"put",
//     headers:{
//       Authorization: "Bearer " +localStorage.getItem("jwt"),

//       "Content-Type":"application/json"

//     },
//     body:JSON.stringify({
//         votes,participant,show:postid
//     })

//   }).then(res=> res.json())
//     .then(results=>{
      
//       setData(results.events)
//     })


   }

    return(

<>
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            <Nav/>

            {/* <Header></Header> */}

            


<div class="relative md:block lg:block hidden">

{/* <img src="bann.jpeg" class="absolute h-full w-full object-cover"/> */}
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div>
  </div>


 {/* <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-8 xl:py-12">
        <div class="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
            <span class="font-bold uppercase text-white md:animate-bounce repeat-1 duration-1000">
                ____________________
            </span>
            <h1 class=" font-bold text-4xl md:text-6xl text-white leading-tight mt-2 ">
            Elevate Your Events, Simplify Your Course
                <br/>
                <br/>
                <br/>
                
          
            </h1>
           
        </div>
    </div>
    */}
   
    </div>


    



        </div>
     

    </div>



  

   
<div class="flex flex-col ">

<img src="/wall.png" class="absolute h-1/2 w-full md:object-cover object-fit overflow-hidden  "/>
{/* 
<video autoPlay loop muted playbackRate={2.0}  class=" absolute h-1/2 w-full object-fill md:h-1/2  md:w-full md:object-cover">
<source src="https://v4.cdnpk.net/videvo_files/video/free/video0467/large_watermarked/_import_614f00b3e16dc1.55454574_FPpreview.mp4" type="video/mp4" />
</video> */}
<div>
<img 
  src={data.url 
    ? data.url[0].replace('/upload/', '/upload/') 
    : "https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph"} 
  className="relative h-60 w-11/12 ml-4 md:h-96 md:w-1/2 object-center md:ml-80 md:mt-24 mt-32 rounded-lg" 
/>
</div>





 {/* <div class=" relative flex  mx-auto">

         <img src="https://img.freepik.com/free-photo/excited-audience-watching-confetti-fireworks-having-fun-music-festival-night-copy-space_637285-559.jpg?w=996&t=st=1698228712~exp=1698229312~hmac=e6d992326774f5a0d719062875e0439936f46b09ea558d4d295af285dc06e732" class=" w-full h-92"/>
 </div>
  */}







{/* <div class="w-full md:p-12  bg-white overflow-auto mt-4 mb-4 relative">


{!(date>=eventDate)? 

<>
<p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 1/3: Book Ticket(s)
                  </p>
<div class="p-4 mx-auto text-center  bg-white border-indigo-500 rounded shadow h-36 md:w-96 w-11/12 ">
      <div class="flex items-center justify-between h-full">
         
          <div class="flex flex-col justify-between h-full">
         
              <div>
               
  <div class="flex items-center">
                                                    <button type="button" class="w-full p-2 text-base text-gray-600 bg-white border rounded-l-xl hover:bg-gray-100"
                                                    onClick={()=>{addToCart(seats-1);}}
                                                    disabled={(data.row&&data.col)|| (seats==0)}

                                                    >
                                                   
                                                    -
                                                    </button>
                            
                                                    <button type="button" class="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100">
                                                    {seats}
                                                    </button>
                            
                            
                                                    <button type="button" class="w-full p-2 text-base text-gray-600 bg-white border-t border-b border-r rounded-r-xl hover:bg-gray-100"
                                                    
                                                    onClick={()=>{addToCart(seats)}}
                                                    disabled={(data.row&&data.col) }
                                                    >
                                                 
                                                    +
                                                    </button>
                                                    </div>
              </div>
              <div>
             
                  <div class="w-full h-4 mt-3 bg-gray-300 rounded-full">
                      <div class=" h-full text-center text-xs text-white bg-indigo-500 rounded-full"
                      style={{ width: `${(size*100)/data.seats}%` }}
                      >
                      </div>
                  </div>
              </div>
          </div>
          <div class="flex flex-col justify-between h-full">
              <p class="text-4xl font-bold text-gray-900 ">
                  <span class="text-sm">
                      $
                  </span>
                  {data.price*seats}
                  
                                           <span class="text-sm text-gray-300">
                      
                  </span>
              </p>
              <button
              onClick={()=>{setPayment(true);handleButtonClick()}}
              disabled={data.occupied>=data.seats || seats<=0}
              type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                 {data.occupied>=data.seats?"Housefull":"Book Ticket" } 
              </button>
              {seats<=0?"Please Select Seats to Proceed":""}
          </div>
      </div>
  </div>
</>
:
  
  <div class="p-4 mx-auto text-center  bg-white border-indigo-500 rounded shadow h-36 md:w-96 w-11/12 ">
<div class="flex justify-center items-center ">
     
      <img src='/ended.png' class="h-24 w-24 "/>
    
  </div>
</div>
  }



<div class="flex items-end justify-between m-4 header">
 <div class="title">
     <p class="mb-4 text-4xl font-bold text-gray-800">
         {data.name}
     </p>
     <p class="text-2xl font-light text-gray-400">
     {data.description}

     </p>
 </div>


 </div>
 
</div> */}


<div class="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 mt-2 mb-4">
    <div class="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg pricing-box lg:max-w-none lg:flex">
        <div class="px-6 py-8 bg-gray-50 dark:bg-gray-800 lg:flex-shrink-1 lg:p-12">
            <h3 class="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9 dark:text-white">
                {data?data.title:""}
            </h3>
            <p class="mt-6 text-base leading-6 text-gray-500 dark:text-gray-200">
                {data?data.body:""}
            </p>
            <div class="mt-8">
                <div class="flex items-center">
                    <h4 class="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-indigo-600 uppercase bg-gray-50 dark:bg-gray-800">
                        Pricing
                    </h4>
                    <div class="flex-1 border-t-2 border-gray-200">
                    </div>
                </div>
                <div className='grid md:grid-cols-3 grid-cols-1 gap-12'> 

                <div class="p-4 mx-auto text-center bg-white border-t-4 border-indigo-500 rounded shadow w-72 dark:bg-gray-800">
    <div class="overflow-hidden">
        <div class="mb-8 text-2xl font-medium text-gray-800 dark:text-white">
            3 Months
        </div>
        <div class="mb-10 text-sm font-light leading-loose text-gray-700 dark:text-gray-50">
            <div class="font-bold">
                5000 products
            </div>
            <div>
                All features
            </div>
            <div>
                Free support
            </div>
        </div>
        <div class="mb-2 text-2xl font-bold text-gray-500 dark:text-gray-200">
            <span>
                249 $
            </span>
        </div>
        <div class="text-sm text-gray-500 dark:text-gray-200">
            / month
        </div>
        <div class="px-4 mt-8">
            <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={(e) => {setAmount(1500);setStartVote(true)}}

            >
                Start
            </button>
        </div>
    </div>
</div>



<div class="p-4 mx-auto text-center bg-white border-t-4 border-indigo-500 rounded shadow w-72 dark:bg-gray-800">
    <div class="overflow-hidden">
        <div class="mb-8 text-2xl font-medium text-gray-800 dark:text-white">
            6 Months
        </div>
        <div class="mb-10 text-sm font-light leading-loose text-gray-700 dark:text-gray-50">
            <div class="font-bold">
                5000 products
            </div>
            <div>
                All features
            </div>
            <div>
                Free support
            </div>
        </div>
        <div class="mb-2 text-2xl font-bold text-gray-500 dark:text-gray-200">
            <span>
                249 $
            </span>
        </div>
        <div class="text-sm text-gray-500 dark:text-gray-200">
            / month
        </div>
        <div class="px-4 mt-8">
            <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={(e) => {setAmount(2500);setStartVote(true)}}

            >
                Start
            </button>
        </div>
    </div>
</div>



<div class="p-4 mx-auto text-center bg-white border-t-4 border-indigo-500 rounded shadow w-72 dark:bg-gray-800">
    <div class="overflow-hidden">
        <div class="mb-8 text-2xl font-medium text-gray-800 dark:text-white">
            1 Year
        </div>
        <div class="mb-10 text-sm font-light leading-loose text-gray-700 dark:text-gray-50">
            <div class="font-bold">
                5000 products
            </div>
            <div>
                All features
            </div>
            <div>
                Free support
            </div>
        </div>
        <div class="mb-2 text-2xl font-bold text-gray-500 dark:text-gray-200">
            <span>
                249 $
            </span>
        </div>
        <div class="text-sm text-gray-500 dark:text-gray-200">
            / month
        </div>
        <div class="px-4 mt-8">
            <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={(e) => {setAmount(3500);setStartVote(true)}}

            >
                Start
            </button>
        </div>
    </div>
</div>



                </div>
            </div>
          
        </div>
        {/* <div class="px-6 py-8 text-center bg-gray-50 dark:bg-gray-700 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
            <p class="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                Fee
            </p>
            <div class="flex items-center justify-center mt-4 text-5xl font-extrabold leading-none text-gray-900 dark:text-white">
                <span>
                    ${data.fee}
                </span>
            </div>
            <p class="mt-4 text-sm leading-5">
                <span class="block font-medium text-gray-500 dark:text-gray-400">
                    Card payments:
                </span>
                <span class="inline-block font-medium text-gray-500  dark:text-gray-400">
                    2.9% + 20p per transaction
                </span>
            </p>
            <div class="mt-6">
                <div class="rounded-md shadow">
                    <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                    onClick={()=>{startChat()}}
                    >
                        Enroll Now
                    </button>
                </div>
            </div>
        </div> */}
    </div>
</div>



{
policy? 
(
<>
<div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>
 {/*content*/}
 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   {/*header*/}
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setPolicy(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
   {/*body*/}
  
  <h1 className='text-lg text-center'>
  Refund and Cancellation Policy

  </h1>


<p className='text-gray-400 text-sm m-2'>
Thank you for purchasing Course through Rig Jr. . Please review our refund and cancellation policy carefully:

No Refunds for Missed Classes: We do not offer refunds if you do not attend the event. All Courses sales are final.

Courses Delivery Issues: If you do not receive your Course after purchase, please contact us immediately. Send an email to rigjr@nexrtech.com with your order details, and we will assist in resolving the issue.

No Refund for Non-Receipt of Course Without Prior Contact: Failure to receive Course without informing us will not be eligible for a refund. It is your responsibility to notify us if there is an issue.

Event Cancellations: In the rare event of a cancellation by B5 Events, a full refund will be provided.

By making a purchase, you agree to these terms.

</p>







 

</div>


</div>
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}
{
startVote? 
(
<>

<div className="fixed inset-0   z-50 flex justify-center items-center overflow-auto ">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="flex items-start justify-end p-2">
        <button
          className="p-1 rounded-full bg-white text-white text-3"
          onClick={() => setStartVote(false)}
        >
          <svg
            className="h-8 w-8 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
      </div>
      {/* Body */}          
     {/* <div className='h-60 overflow-y-auto'> */}

     <p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 1/3: Click Buy Now
                  </p>
      <div className="flex flex-col items-center justify-center p-6">


      
        <div className="w-full">

          Nu. {amount}
          {/* <input
            type="number"
            className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300 focus:outline-none mb-4"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
         */}
          <button
            type="button"
            className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            onClick={() => addVote()}
          >
            Buy Now
          </button>
        </div>
    
        </div>


      {/* </div> */}
    </div>
  </div>
</div>




</>
)


:null

}


{
payment? 
(
<>

<div className="fixed inset-0 z-50 flex justify-center items-center overflow-auto">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="flex items-start justify-end p-2">
        <button
          className="p-1 rounded-full bg-white text-white text-3"
          onClick={() => setPayment(false)}
        >
          <svg
            className="h-8 w-8 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
      </div>
      {/* Body */}
      <p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 2/3: Select Bank and Enter Acc no
                  </p>
      <div className="overflow-auto max-h-full">
        <div className="flex md:flex-row flex-col bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="flex-auto p-6">
           
            <div className="py-1">
              <span className="px-1 text-sm text-gray-600">Bank</span>
              <select
                className="block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-52 focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                name="animals"
                value={bfs_remitterBankId}
                onChange={(e) => {
                  setbfs_remitterBankId(e.target.value);
                }}
              >
                <option>Select Your Bank</option>
                <option value="1010">Bank of Bhutan</option>
                <option value="1020">Bhutan National Bank</option>
                <option value="1030">Druk PNBL</option>
                <option value="1040">T Bank Ltd</option>
                <option value="1050">BDBL</option>
                <option value="1060">DK Bank Ltd</option>
              </select>
            </div>
            <div className="py-1">
              <span className="px-1 text-sm text-gray-600">Account No</span>
              <input
                type="text"
                className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300 focus:outline-none"
                placeholder="Account No"
                value={bfs_remitterAccNo}
                onChange={(e) => setbfs_remitterAccNo(e.target.value)}
              />
            </div>
            <div className="flex mb-4 text-sm font-medium">
              <button
                type="button"
                className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                onClick={sendAERequest}
              >
                Proceed
              </button>
            </div>

         
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



{/* <div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>

 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setPayment(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
  
   


   <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >





<div className="lg:flex w-full">
 
 <div className="w-full md:h-96    px-5  relative overflow-auto">




<div class="flex flex-col w-full max-w-md py-1 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-6">
 
  <div class="mt-1">
          <div class="flex flex-col mb-1">





<div className="py-1">


<span className="px-1 text-sm text-gray-600"> Bank</span>

<select class="block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-52 focus:outline-none focus:ring-primary-500 focus:border-primary-500" name="animals"

value={bfs_remitterBankId}
onChange={(e)=>{setbfs_remitterBankId(e.target.value)}}
>
    <option >
        Select Your Bank
    </option>
    <option value="1010">
        Bank of Bhutan
    </option>
    <option value="1020">
        Bhutan National Bank
    </option>
    <option value="1030">
        Druk PNBL
    </option>
    <option value="1040">
        T Bank Ltd
    </option>
    <option value="1050">
        BDBL 
    </option>
    <option value="1060">
        DK Bank Ltd
    </option>
</select>

</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Account No</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Account No"
value={bfs_remitterAccNo}
onChange={(e)=>setbfs_remitterAccNo(e.target.value)}
>

</input>
</div>



<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={sendAERequest}
>
    Valider
</button>






             
              </div>


   

                 
                 
          </div>
         
      </div>
     



</div>

<div className=" md:block md:w-1/2 w-full  bg-white  ">


<div class="m-auto overflow-auto rounded-lg shadow-lg cursor-pointer h-90 w-11/12">
         
         <img alt="blog photo" src={votefor.pic?votefor.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover w-full max-h-40"/>
             <div class="w-full p-4 bg-white ">
                 <p class="font-medium text-indigo-500 text-md">
                     {votefor.name}
                 </p>
                 <div class="flex items-center">
     <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
     
      <p class="text-md text-black  ml-2">
     
     {votefor.dzongkhag}
 </p>
</div>
   
                 <div class="flex items-center">
                 <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
     
      <p class="text-md text-black  ml-2">
     
     {votefor.code} Seats
 </p>
</div>
   
   
                 <div class="flex items-center">
                 <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>
     
      <p class="text-md text-black ml-2">
     
      {amount/data.price} Votes
 </p>
</div>
                


                 
                
             </div>
     </div>





</div>
   </div>


 </div>



 

</div>


</div> */}
<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</>
)


:null

}
{
pay? 
(
<>

<div className="fixed inset-0 z-50 flex justify-center items-center overflow-auto">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="flex items-start justify-end p-2">
        <button
          className="p-1 rounded-full bg-white text-white text-3"
          onClick={() => setPay(false)}
          >
          <svg
            className="h-8 w-8 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
      </div>
      {/* Body */}
      <p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 3/3: Enter OTP and click Pay
                  </p>
      <div className="overflow-auto max-h-full">
        <div className="flex md:flex-row flex-col bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="flex-auto p-6">
           
            <div className="py-1">
              <span className="px-1 text-sm text-gray-600">OTP</span>
              <input
                type="text"
                className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
                          bg-white border-2 border-gray-300 placeholder-gray-400  
                          focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  
                          focus:outline-none"
                placeholder="Enter OTP"
                value={bfs_remitterOtp}
                onChange={(e)=>setbfs_remitterOtp(e.target.value)}
              />
            </div>
            <div className="flex mb-4 text-sm font-medium">
              <button
                type="button"
                         class="  focus:ring-indigo-500 focus:ring-offset-indigo-200 
                          text-white w-full transition ease-in duration-200 
                          text-center text-base font-semibold shadow-md 
                          focus:outline-none focus:ring-2 focus:ring-offset-2  
                          rounded-lg "
                onClick={()=>{sendDRRequest()}}
              >
                Pay
              </button>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


{/* <div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>

 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   <div className="flex rounded-full items-start  ">
    
   </div>
  
   


   <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >





<div className="lg:flex w-full">
 
 <div className="w-full md:h-96    px-5  relative overflow-auto">




<div class="flex flex-col w-full max-w-md py-1 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-6">
 
  <div class="mt-1">
          <div class="flex flex-col mb-1">







<div className="py-1">
<span className="px-1 text-sm text-gray-600">OTP</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Enter OTP"
value={bfs_remitterOtp}
onChange={(e)=>setbfs_remitterOtp(e.target.value)}
>

</input>
</div>




<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{sendDRRequest()}}
>
    Proceed
</button>


             
              </div>

   

                 
                 
          </div>
         
      </div>
     



</div>


   </div>


 </div>



 

</div>


</div> */}
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}

{
loading? 
(
<>

<div className="fixed inset-0 z-50 flex justify-center items-center overflow-auto">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
     
      {/* Body */}
     
      <div className="overflow-auto max-h-full">
        <div className="flex md:flex-row flex-col bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="flex-auto p-6">
           
          <svg class="h-20 w-20 text-indigo-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg> 
          
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


{/* <div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>

 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   <div className="flex rounded-full items-start  ">
    
   </div>
  
   


   <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >





<div className="lg:flex w-full">
 
 <div className="w-full md:h-96    px-5  relative overflow-auto">




<div class="flex flex-col w-full max-w-md py-1 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-6">
 
  <div class="mt-1">
          <div class="flex flex-col mb-1">







<div className="py-1">
<span className="px-1 text-sm text-gray-600">OTP</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Enter OTP"
value={bfs_remitterOtp}
onChange={(e)=>setbfs_remitterOtp(e.target.value)}
>

</input>
</div>




<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{sendDRRequest()}}
>
    Proceed
</button>


             
              </div>

   

                 
                 
          </div>
         
      </div>
     



</div>


   </div>


 </div>



 

</div>


</div> */}
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}





</div>
        














<Footer page="events"></Footer>
</main>







</>

  


    );
}

export default SingleDzongkha;