
// import React,{useContext, useState} from 'react'
// import {Link, useNavigate} from 'react-router-dom'

// import { ToastContainer, toast } from 'react-toastify';
// // import Header from '../components/Header';
// import 'react-toastify/dist/ReactToastify.css';

// import {UserContex} from '../App'
// // import Footer from '../components/Footer';


// const Registration_men = ()=>{
//   const {state, dispatch }= useContext(UserContex)
//   const history =useNavigate();
//   const [otp,setOTP]= useState("")
//   const [showotp,setShowOTP]= useState(false)
//   const [progress,setProgress]= useState(false)
//   const[id,setId] = useState("")  
//   const[url,setUrl] = useState("")  
//   const[name,setName]= useState("")
//   const[phone,setPhone]= useState("")
//   const[address,setAddress]= useState("")
//   const[email,setEmail]= useState("")
//   const[password,setPassword]= useState("")
//   const[confirmpassword,setConfirmPassword]= useState("")
//   const [isValidPassword,setisValidPassword]= useState(false)
//   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
//   const [showprocess,setShowProcess]= useState(false)

//   const PostData =()=>{

//     setShowProcess(true)

//     fetch("/signupTeacher",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json"
//       },
//       body: JSON.stringify({
        
//           password,
//           email:email,
//           address,
//           name,
//           phone,
//           otp,
//           pic:url
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)
//         setShowProcess(false)


//       }
//       else{
//         console.log("Login Successful")
//         toast.success("Signsup Successful")

        
//           history('/loginMentor')
//       }
//   })
//   .catch(err=>{
//       console.log(err)
//   })

//        }



//        const Verify = ()=>{
//         fetch("/sendotp",{
//           method:"post",
//           headers:{
//               "Content-Type":"application/json"
//           },
//           body: JSON.stringify({
            
//               email:email,
              
//           })
          
//       }).then(res=>res.json())
//       .then(data=> {
         
//           if(data.error){
  
//               toast.error(data.error)
//           }
//           else{
              
//               toast.success("Check Your Mail")
//               setShowOTP(true)
//           }
//       })
//       .catch(err=>{
//           console.log(err)
//       })
    
//        }


//        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

// const handleFileUpload = (event) => {
//   const file = event.target.files[0];

//   if (!file) return;

//   // Validate file type (allowing only images)
//   const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
//   if (!allowedTypes.includes(file.type)) {
//     alert('Invalid file type. Please upload a JPEG, PNG, or GIF image.');
//     return;
//   }

//   // Validate file size
//   if (file.size > MAX_FILE_SIZE) {
//     alert('File size exceeds 5MB. Please upload a smaller file.');
//     return;
//   }

//   // Proceed with upload
//   uploadppt(file);
// };


//        const uploadppt=({target:{files}})=>{

//         setProgress(true)
    
//         let data = new FormData()
    
//         for(const file of files){
    
//        data.append('uploadimage', file)
//        data.append('name',file.name)
//     }
    
    
    
//            fetch("/uploadimage", {
    
//              method:"post",
//              body: data
//          })
//          .then(res=>res.json())
//          .then(data=>{
    
//               setUrl(data.url)
//               setProgress(false)
    
          
    
           
//          })
//          .catch(err=>{
//              console.log(err)
//          })
    
//     }
      


//     return(


// <>
// {/* <Header></Header> */}
//   {/* component */}
//   <style dangerouslySetInnerHTML={{__html: "@import url('https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css')" }} />
//   <div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center px-5 py-5">
//     <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-y-scroll" style={{maxWidth: 1000}}>
//       <div className="md:flex w-full">
//         <div className="hidden md:block w-1/2 bg-white py-10 px-10">

//             <img className='h-full' src='https://img.freepik.com/free-vector/tablet-login-concept-illustration_114360-7963.jpg?size=626&ext=jpg&ga=GA1.1.16292083.1700716592&semt=sph'></img>
//         </div>
//         <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
//           <div className="text-center mb-10">
//             <h1 className="font-bold text-3xl text-gray-900">Register </h1>
//           </div>
//           <div>
//           <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">

       
// <div className="py-1">
//   <span className="px-1 text-sm text-gray-600">Name </span>
//     <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
//   bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
  
//     placeholder="Name"
//     value={name}
//     onChange={(e)=>setName(e.target.value)}
//     disabled={name.length>50?true:false}
//     >

//     </input>
//   </div>

//   <div className="py-1">
//   <span className="px-1 text-sm text-gray-600">Phone</span>
//     <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
//   bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
  
//     placeholder="Phone No."
//     value={phone}
//     onChange={(e)=>setPhone(e.target.value)}
//     >

//     </input>
//   </div>
//   </div>

//             <div className="flex -mx-3">
//               <div className="w-full px-3 mb-5">
//                 <label htmlFor className="text-md text-gray-600 font-semibold px-1">Email</label>
//                 <div className="flex">
//                   <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
//                   <input type="email" required 
//                   className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
//                   placeholder="youremail@example.com"
//                   value={email}
//                   onChange={(e)=>setEmail(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </div>
//             {/* <div className="flex -mx-3">
//               <div className="w-full px-3 mb-5">
//                 <label htmlFor className="text-md text-gray-600 font-semibold px-1">Address</label>
//                 <div className="flex">
//                   <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
//                   <svg class="h-6 w-6 text-gray-400"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
// </svg>
                    
//                     </div>
//                   <input type="text" required 
//                   className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
//                   placeholder="Your Address"
//                   value={address}
//                   onChange={(e)=>setAddress(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </div> */}
//           <div className="flex -mx-3">
//               <div className="w-full px-3 mb-5">
//                 <label htmlFor className="text-md  text-gray-600 font-semibold px-1">Password</label>
//                 <div className="flex">
//                   <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg" /></div>
//                   <input type="password"  required
//                   className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
//                   placeholder="Password"
//                   value={password}
//                   onChange={(e)=>{setPassword(e.target.value);
                  
//                     setisValidPassword(passwordRegex.test(password));

//                   }}
//                   />
//                 </div>
//                 <h3 class={`${isValidPassword?"hidden":"text-red-500"} `}>Password should be at least 8 digits, contain special character and at least number </h3>

//               </div>
//             </div>
//             <div className="flex -mx-3">
//               <div className="w-full px-3 mb-5">
//                 <label htmlFor className="text-md  text-gray-600 font-semibold px-1">Confirm Password</label>
//                 <div className="flex">
//                   <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg" /></div>
//                   <input type="password"  required
//                   className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
//                   placeholder="Password"
//                   value={confirmpassword}
//                   onChange={(e)=>setConfirmPassword(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </div>
           
//             <h3 class={`${password===confirmpassword?"hidden":"text-red-500"} `}>Password didn't match </h3>

//             <div className="col-span-4 w-full mb-5 ">
//                         <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
//          <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
//              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
//          </svg>
//         <span className="mt-2 text-base leading-normal">Upload Picture</span>
//         <input type='file' className="hidden" 
//         onChange={handleFileUpload}
//         accept="image/*" // Restrict to images only

//         />
        
//     </label>
//                                    </div>

                                        
//             {progress?
//                 <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
//             :<></>}
   

// {url?<img className=" w-28 h-28  rounded-lgflex items-center " src={url} ></img>:null
// }
 
//             <label class="flex items-center mb-3 space-x-3">
//             <input type='checkbox' class="w-4 h-4">
//             </input>
//                                         <span class="font-normal text-gray-700 ">
//                                             I agree to terms and contition 
//                                         </span>
//                                     </label> 


//             {/* <label class="flex items-center mb-3 space-x-3">
//                                     <input type="checkbox"  class="form-tick appearance-none bg-white bg-check h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"/>
//                                         <span class="font-normal text-gray-700 ">
//                                             I agree to terms and contition 
//                                         </span>
//                                     </label> */}
//             <div className="w-full px-3 mb-5">
//             <button className={`block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold ${isValidPassword&&(password===confirmpassword)?"cursor-pointer":"cursor-not-allowed"}`}
//                 disabled = {!(isValidPassword&&password===confirmpassword)}
//                 onClick={()=> Verify()}
//                 >Sign up</button>

//                <ToastContainer/>

//               </div>


//             <div className=" px-12 mb-2 object-center"> 
//               <a href="/reset">
//                   Forgot Password?
//               </a>
//               <br></br>
//               <a href="/resetuserpassword">
//               Already have an account? Login
//               </a>
//             </div>
              
//     </div>
// </div>

        
//           </div>
//         </div>

//         {
// showotp? 
// (
//   <>
//     <div
//       className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
//     >

//         {/*content*/}
//         <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-80 bg-white outline-none focus:outline-none">
//           {/*header*/}
//           <div className="flex rounded-full items-start  ">
            
//             <button
//               className="p-1 ml-auto rounded-full bg-white text-white text-3 "
//               onClick={() => setShowOTP(false)}
//             >
// <svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

//    </button>
//           </div>
//           {/*body*/}
         
          





    

//       <div className="md:flex w-46">
        
//         <div className="w-full     px-5 md:px-10 relative overflow-auto">



// <div class="flex flex-col w-full max-w-md px-4 py-1 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-10">
         
//          <div class="mt-1">
             


     

//      <div className="py-1">


//      <span className="px-1 text-sm text-gray-600"> Enter the OTP sent to {email}</span>
//      <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
//      bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
     
//      placeholder="OTP"
//      value={otp}
//      onChange={(e)=>setOTP(e.target.value)}
//      >
     
//      </input>
//      </div>
//      <button type="submit" class="
// space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-purple-500 hover:bg-purple-600 placeholder-gray-400  text-white "
// onClick={()=>{PostData()}}
// >
//     Submit 
// </button>
   
     
//      </div>





        
                
//              </div>
            


       
// </div>


//           </div>
       
        
//         </div>



        

//       </div>

      
//     {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
//   </>
// )


// :null

// }


// {
// showprocess? 
// (
//   <>
//     <div
//       className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
//     >

//         {/*content*/}
//         <div className=" border-0 relative flex flex-col w-80 bg-none outline-none focus:outline-none">
//           {/*header*/}
          
//           {/*body*/}
         
          





    

        

//       <svg class="h-20 w-20 text-purple-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>


       
        
//         </div>



        

//       </div>

      
//     {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
//   </>
// )
// :null

// }
//       </div>

//       {/* <Footer></Footer> */}

// </>
  


//     );
// }

// export default Registration_men;

 


import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { UserContex } from '../App';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import HomeHeader from './HomeHeader';

const Registration_men = () => {
  const { state, dispatch } = useContext(UserContex);
  const history = useNavigate();
  const [otp, setOTP] = useState("");
  const [showotp, setShowOTP] = useState(false);
  const [progress, setProgress] = useState(false);
  const [id, setId] = useState("");
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [isValidPassword, setisValidPassword] = useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const [showprocess, setShowProcess] = useState(false);

  const PostData = () => {
    setShowProcess(true);

    fetch("/signupTeacher", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        password,
        email: email,
        address,
        name,
        phone,
        otp,
        pic: url
      })
    }).then(res => res.json())
      .then(data => {
        if (data.error) {
          toast.error(data.error);
          setShowProcess(false);
        } else {
          console.log("Signup Successful");
          toast.success("Signup Successful");
          history('/loginMentor');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const Verify = () => {
    fetch("/sendotp", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
      })
    }).then(res => res.json())
      .then(data => {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Check Your Mail");
          setShowOTP(true);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleGoogleResponse = (response) => {
    const { credential } = response;
    fetch(`/google-auth`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ credential })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          toast.error(data.error);
        } else {
          setEmail(data.email);
          setName(data.name);
          setUrl(data.picture);
          // Optionally, navigate to login or dashboard page
          history("/loginMentor");
        }
      });
  };

  return (
    <>
    <HomeHeader></HomeHeader>
      <div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center px-5 py-5">
        <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-y-scroll" style={{ maxWidth: 1000 }}>
          <div className="md:flex w-full">
            <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
              <div className="text-center mb-10">
                <h1 className="font-bold text-3xl text-gray-900">Register</h1>
              </div>



      {/* Wrap GoogleLogin inside GoogleOAuthProvider */}
      <GoogleOAuthProvider clientId="65127742790-v9qd3eupg12ksbkbs6mjl285qvjvi8b8.apps.googleusercontent.com">
  <GoogleLogin
    onSuccess={credentialResponse => {
      const googleToken = credentialResponse.credential;

      // Send token to backend for sign-up
      fetch("/google-signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ token: googleToken })
      })
        .then(res => res.json())
        .then(data => {
          if (data.error) {
            toast.error(data.error);
          } else {
            localStorage.setItem("jwt", data.token);
            localStorage.setItem("user", JSON.stringify(data.user));
            dispatch({ type: "USER", payload: data.user });
            toast.success("Sign-up Successful");

            history('/MentorDashboard');
          }
        })
        .catch(err => {
          console.error("Google sign-up failed:", err);
          toast.error("Google sign-up failed");
        });
    }}
    onError={() => {
      console.log('Google Sign-up Failed');
      toast.error("Google sign-up failed");
    }}
  />
</GoogleOAuthProvider>

<div className="flex items-center space-x-2">
  <div className="flex-grow border-t border-gray-300"></div>
  <span className="text-gray-500">OR</span>
  <div className="flex-grow border-t border-gray-300"></div>
</div>

              <div>

                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                  <div className="py-1">
                    <span className="px-1 text-sm text-gray-600">Name</span>
                    <input type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300 focus:outline-none"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)} />
                  </div>

                  <div className="py-1">
                    <span className="px-1 text-sm text-gray-600">Phone</span>
                    <input type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300 focus:outline-none"
                      placeholder="Phone No."
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)} />
                  </div>
                </div>

                <div className="w-full px-3 mb-5">
                  <label htmlFor className="text-md text-gray-600 font-semibold px-1">Email</label>
                  <input type="email" required
                    className="w-full pl-10 pr-3 py-2 placeholder-gray-400 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                    placeholder="youremail@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div className="w-full px-3 mb-5">
                  <label htmlFor className="text-md text-gray-600 font-semibold px-1">Password</label>
                  <input type="password" required
                    className="w-full pl-10 pr-3 py-2 placeholder-gray-400 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value); setisValidPassword(passwordRegex.test(password)); }} />
                  <h3 className={`${isValidPassword ? "hidden" : "text-red-500"}`}>Password should be at least 8 digits, contain a special character, and at least one number</h3>
                </div>

                <div className="w-full px-3 mb-5">
                  <label htmlFor className="text-md text-gray-600 font-semibold px-1">Confirm Password</label>
                  <input type="password" required
                    className="w-full pl-10 pr-3 py-2 placeholder-gray-400 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                    placeholder="Confirm Password"
                    value={confirmpassword}
                    onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>

                <h3 className={`${password === confirmpassword ? "hidden" : "text-red-500"}`}>Password didn't match</h3>

                <div className="w-full mb-5">
                  <button className={`w-full bg-blue-300 text-white rounded-lg px-3 py-3 font-semibold ${isValidPassword && password === confirmpassword ? "cursor-pointer" : "cursor-not-allowed"}`}
                    disabled={!(isValidPassword && password === confirmpassword)}
                    onClick={Verify}>Sign up</button>
                </div>

                <ToastContainer />

          
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration_men;
