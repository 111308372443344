import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContex } from '../App';
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import 'react-toastify/dist/ReactToastify.css';
import HomeHeader from './HomeHeader';

const ResetStd = () => {
  const { state, dispatch } = useContext(UserContex);
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);

  const PostData = () => {
    if (!captchaToken) {
      toast.error("Please complete the CAPTCHA.");
      return;
    }

    fetch("/resetstd-password", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        'g-recaptcha-response': captchaToken // Send captcha token to backend
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("If this email is registered, you will receive a reset link.");
          history('/loginMentor');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
    <HomeHeader></HomeHeader>
      <div className="min-w-screen min-h-screen bg-white flex items-center justify-center px-5 py-5">
        <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden" style={{ maxWidth: 1000 }}>
          <div className="md:flex w-full">
            <div className="hidden md:block w-1/2 bg-blue-300 py-10 px-10">
              {/* Add an image if needed */}
            </div>
            <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
              <div className="text-center mb-10">
                <h1 className="font-bold text-3xl text-gray-900">Reset Organizer's Password</h1>
              </div>
              <div>
                <div className="flex -mx-3">
                  <div className="w-full px-3 mb-5">
                    <label className="text-md text-gray-600 font-semibold px-1">Email</label>
                    <div className="flex">
                      <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                        <i className="mdi mdi-email-outline text-gray-400 text-lg" />
                      </div>
                      <input
                        type="email"
                        required
                        className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                        placeholder="youremail@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* Add reCAPTCHA */}
                <div className="w-full px-3 mb-5 flex justify-center">
                  <ReCAPTCHA
                    sitekey="6Ldh888qAAAAAHDbQkgMfcx7VF71NLIbZc33afJx"  
                    onChange={(token) => setCaptchaToken(token)}
                  />
                </div>

                {/* Reset Button */}
                <div className="w-full px-3 mb-5">
                  <button
                    className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                    onClick={PostData}
                  >
                    Reset
                  </button>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetStd;
